import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';

import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell, DataTableRowProps } from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';
import { IconButton } from '@rmwc/icon-button';
import { Drawer, DrawerProps } from '@rmwc/drawer';
import { Button, ButtonProps } from '@rmwc/button';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';
import SearchField from '../../../components/SearchField';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyDataComponent from '../../../components/EmptyDataComponent';
import SelectField from '../../../components/SelectField';
import RoundButton from '../../../components/Button/RoundButton';
import { UserAvatar, UserAvatarProps } from '../../../components/UserAvatar';

import { Defaults, RoleEnum, theme } from '../../../constants';
import { useFlagUser, useGetFlaggedUser, useGetUsersByPage, useUnflagUser } from '../../../lib/api/user.hooks';
import parseSort from '../../../lib/parseSort';
import { statesCities, states } from '../../../data/states-cities';
import { useError } from '../../../lib/error.hook';
import { User } from '../../../types/User';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';

import { filterIcon, flagIcon, flagFilledIcon, listIcon } from '../../../assets/images';

export enum UserStatus {
  ADMIN = 'Admin',
  ELECTED_OFFICIAL = 'Elected Official',
  CAMPAIGN_PROFILE = 'Campaign Profile',
  PURPLE_AMBASSADOR = 'Purple Ambassador',
  CAMPUS_AMBASSADOR = 'Campus Ambassador',
  VERIFIED_CITIZEN = 'Verified Citizen',
  USER = 'User'
}
const userStatusStyles: { [status in UserStatus]: React.CSSProperties } = {
  [UserStatus.ADMIN]: { backgroundColor: theme.primary, color: theme.white },
  [UserStatus.ELECTED_OFFICIAL]: { backgroundColor: theme.primary, color: theme.white },
  [UserStatus.CAMPAIGN_PROFILE]: { backgroundColor: theme.purpleHeart, color: theme.white },
  [UserStatus.PURPLE_AMBASSADOR]: { backgroundColor: theme.pink, color: theme.white },
  [UserStatus.CAMPUS_AMBASSADOR]: { backgroundColor: theme.blue, color: theme.white },
  [UserStatus.VERIFIED_CITIZEN]: { backgroundColor: theme.verifiedBlue, color: theme.white },
  [UserStatus.USER]: { backgroundColor: theme.lightLilac, color: theme.darkGrey }
};

function UserList() {
  const history = useHistory();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    sort: StringParam,
    limit: NumberParam,
    status: StringParam,
    state: StringParam,
    city: StringParam
  });
  const { page, search, sort, limit, status, state, city } = query;
  const [debouncedSearchFn] = useDebouncedCallback((value: string) => {
    setQuery({ search: value, page: 0 });
  }, Defaults.SEARCH_DEBOUNCE_MS);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const { data, count, isLoading, error, get } = useGetUsersByPage();

  useError([error]);

  useEffect(() => {
    const searchString = search && { search };
    get({
      page: Number(page) || 0,
      sort: String(sort || ''),
      limit: Number(limit || Defaults.LIST_PAGE_SIZES[0]),
      role: getRole(status as UserStatus) || '',
      state: String(state || ''),
      city: String(city || ''),
      excludeCampaignProfile: status === UserStatus.ELECTED_OFFICIAL,
      campaignProfileOnly: status === UserStatus.CAMPAIGN_PROFILE,
      ...searchString
    });
  }, [get, page, sort, search, limit, status, state, city]);

  useEffect(() => {
    if (drawerOpen) {
      setSelectedState(state || '');
      setSelectedCity(city || '');
    }
  }, [drawerOpen, state, city]);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setQuery({ sort: newSort });
  };

  const editUser = (userId: string) => {
    history.push(`/cms/users/${userId}`);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setQuery({ page });
  };

  const setNewLimit = (pageSize: number) => {
    // reset to first page
    setNewPage(1);
    setQuery({ limit: pageSize });
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  const onFilterStatus = (selectedStatus?: UserStatus) => {
    setQuery({ status: selectedStatus, page: 0 });
  };

  const onFilter = () => {
    setQuery({ state: selectedState, city: selectedCity, page: 0 });
    setDrawerOpen(false);
  };

  const onClearFilter = () => {
    setSelectedState('');
    setSelectedCity('');
  };

  const currentStateCities = useMemo(() => statesCities.find(item => item.name === selectedState), [selectedState]);
  const cities = useMemo(() => (currentStateCities?.cities || []).map(item => ({ name: item.name })), [currentStateCities]);

  return (
    <>
      <FilterDrawer dir="rtl" modal open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <FilterDrawerHeader dir="ltr">
          <FilterDrawerTitle>Filter</FilterDrawerTitle>
          <IconButton icon="close" onClick={() => setDrawerOpen(false)} />
        </FilterDrawerHeader>

        <FilterDrawerContent dir="ltr">
          <FilterDrawerLabel>State</FilterDrawerLabel>
          <FilterSelect>
            <SelectField
              value={{ name: selectedState || '' }}
              name="state"
              options={states}
              onChange={(val: { name: string }) => {
                setSelectedState(val?.name || '');
                setSelectedCity('');
              }}
              placeholder="Select State"
              changeOptionLabel="name"
              changeOptionValue="name"
            />
          </FilterSelect>

          <FilterDrawerLabel>City</FilterDrawerLabel>
          <FilterSelect>
            <SelectField
              value={{ name: selectedCity }}
              name="city"
              options={cities}
              onChange={(val: any) => setSelectedCity(val?.name || '')}
              placeholder="Select City"
              changeOptionLabel="name"
              changeOptionValue="name"
            />
          </FilterSelect>

          <FilterButtonGroup>
            <FilterSubmitButton onClick={onFilter}>Filter</FilterSubmitButton>
            <FilterClearButton onClick={onClearFilter}>Clear All</FilterClearButton>
          </FilterButtonGroup>
        </FilterDrawerContent>
      </FilterDrawer>

      <Container className="main-content">
        <ContentHeader>
          <StyledBreadCrumbs crumbs={['Accounts', 'All']} textStyles={{ fontSize: 24, fontWeight: 700 }} />
          <StyledSearchField
            searchString={search}
            onSearch={search => {
              debouncedSearchFn(search);
            }}
          />
          <AddUserButton unelevated>Add User +</AddUserButton>
        </ContentHeader>

        <StatusFilter>
          <StatusFilterButtonGroup>
            <StatusFilterButton selected={!status} onClick={() => onFilterStatus()}>
              All
            </StatusFilterButton>
            <StatusFilterButton
              selected={status === UserStatus.ELECTED_OFFICIAL}
              onClick={() => onFilterStatus(UserStatus.ELECTED_OFFICIAL)}
            >
              Elected Officials
            </StatusFilterButton>
            <StatusFilterButton
              selected={status === UserStatus.CAMPAIGN_PROFILE}
              onClick={() => onFilterStatus(UserStatus.CAMPAIGN_PROFILE)}
            >
              Campaign Profiles
            </StatusFilterButton>
            <StatusFilterButton
              selected={status === UserStatus.PURPLE_AMBASSADOR}
              onClick={() => onFilterStatus(UserStatus.PURPLE_AMBASSADOR)}
            >
              Purple Ambassadors
            </StatusFilterButton>
            <StatusFilterButton
              selected={status === UserStatus.CAMPUS_AMBASSADOR}
              onClick={() => onFilterStatus(UserStatus.CAMPUS_AMBASSADOR)}
            >
              Campus Ambassadors
            </StatusFilterButton>
            <StatusFilterButton selected={status === UserStatus.USER} onClick={() => onFilterStatus(UserStatus.USER)}>
              Citizens
            </StatusFilterButton>
          </StatusFilterButtonGroup>
        </StatusFilter>

        <ContentBody>
          <>
            <TableContainer>
              <TableHeader>
                <ListIcon src={listIcon} />
                <TableHeaderTitle>Accounts</TableHeaderTitle>
                <IconButton icon={filterIcon} onClick={() => setDrawerOpen(prev => !prev)} />
              </TableHeader>

              {!isLoading && count === 0 ? (
                <EmptyDataComponent message="No Accounts" />
              ) : (
                <Table stickyRows={1}>
                  <FullWidthDataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <TableHeadCell
                          sort={sortObj.column === 'id' ? sortObj.direction : null}
                          onSortChange={dir => {
                            setNewSort('id', dir);
                          }}
                        >
                          ID #
                        </TableHeadCell>
                        <TableHeadCell
                          sort={sortObj.column === 'profile.name' ? sortObj.direction : null}
                          onSortChange={dir => {
                            setNewSort('profile.name', dir);
                          }}
                        >
                          Name
                        </TableHeadCell>
                        <TableHeadCell
                          sort={sortObj.column === 'profile.username' ? sortObj.direction : null}
                          onSortChange={dir => {
                            setNewSort('profile.username', dir);
                          }}
                        >
                          Username
                        </TableHeadCell>
                        <TableHeadCell
                          sort={sortObj.column === 'profile.email' ? sortObj.direction : null}
                          onSortChange={dir => {
                            setNewSort('profile.email', dir);
                          }}
                        >
                          Email
                        </TableHeadCell>
                        <TableHeadCell>City / State</TableHeadCell>
                        <TableHeadCell>Status</TableHeadCell>
                        <TableHeadCell>Flag</TableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {!isLoading && !error && data && data.map(row => <UserRow key={row.id} user={row} editUser={editUser} />)}
                    </DataTableBody>
                  </FullWidthDataTableContent>
                </Table>
              )}
              {isLoading && <Spinner />}
            </TableContainer>
            {count !== null && count !== 0 && !error && (
              <FullWidthBar>
                <Typography use="subtitle1">Total: {count.toLocaleString()}</Typography>
                <StyledPagination
                  current={Number(page) + 1 || 1}
                  count={count}
                  maxSize={Number(limit || Defaults.LIST_PAGE_SIZES[0])}
                  onPageChange={(page: number) => setNewPage(page)}
                  onPageSizeChange={(pageSize: number) => setNewLimit(pageSize)}
                />
              </FullWidthBar>
            )}
          </>
        </ContentBody>
      </Container>
    </>
  );
}

const getRole = (status: UserStatus) => {
  switch (status) {
    case UserStatus.ELECTED_OFFICIAL:
    case UserStatus.CAMPAIGN_PROFILE:
      return RoleEnum.ELECTED_OFFICIAL;
    case UserStatus.PURPLE_AMBASSADOR:
      return RoleEnum.AMBASSADOR;
    case UserStatus.CAMPUS_AMBASSADOR:
      return RoleEnum.CAMPUS_AMBASSADOR;
    case UserStatus.VERIFIED_CITIZEN:
    case UserStatus.USER:
      return RoleEnum.USER;
    default:
      return;
  }
};

type UserRowProps = {
  user: User;
  editUser: (userId: string) => void;
};
const UserRow = ({ user, editUser }: UserRowProps) => {
  const [isFlagged, setIsFlagged] = useState(false);
  const [isLoadingFlag, setIsLoadingFlag] = useState(false);

  const { get: fetchFlaggedUser, ...getFlaggedUser } = useGetFlaggedUser(user.id);
  const flagUser = useFlagUser(user.id);
  const unflagUser = useUnflagUser(user.id);

  useEffect(() => {
    (async () => {
      setIsLoadingFlag(true);
      await fetchFlaggedUser();
      setIsLoadingFlag(false);
    })();
  }, [fetchFlaggedUser]);

  useEffect(() => {
    if (!getFlaggedUser.error && !getFlaggedUser.isLoading) {
      setIsFlagged(!!getFlaggedUser.data);
    }
  }, [getFlaggedUser.data, getFlaggedUser.error, getFlaggedUser.isLoading]);

  useEffect(() => {
    if (flagUser.data && !flagUser.error && !flagUser.isLoading) {
      setIsFlagged(true);

      CmsSnackbarQueue.notify({
        title: `${user.profile.name} has been flagged.`
      });
    }
  }, [flagUser.data, flagUser.error, flagUser.isLoading, user.profile.name]);

  useEffect(() => {
    if (unflagUser.data !== null && !unflagUser.error && !unflagUser.isLoading) {
      setIsFlagged(false);

      CmsSnackbarQueue.notify({
        title: `${user.profile.name} has been unflagged.`
      });
    }
  }, [unflagUser.data, unflagUser.error, unflagUser.isLoading, user.profile.name]);

  const onFlag = async () => {
    if (!isFlagged) {
      setIsLoadingFlag(true);
      await flagUser.flag();
      setIsLoadingFlag(false);
    } else {
      setIsLoadingFlag(true);
      await unflagUser.unflag();
      setIsLoadingFlag(false);
    }
  };

  const { name, username, email, verified, media } = user.profile || {};
  const { state, city } = user.vurplytic || {};

  const { smallUrl, mediumUrl, largeUrl, originalUrl } = media || {};
  const userImage = smallUrl || mediumUrl || largeUrl || originalUrl || '';

  let stateAndCity = '';
  if (state && city) {
    stateAndCity = `${city} / ${state}`;
  } else if (state) {
    stateAndCity = state;
  } else if (city) {
    stateAndCity = city;
  }

  const roles = user.roles || [];
  const isAdmin = roles.find(role => role.name === RoleEnum.ADMIN);
  const isOfficial = roles.find(role => role.name === RoleEnum.ELECTED_OFFICIAL);
  const isCampaignProfile = isOfficial && user.campaignProfile?.active;
  const isAmbassador = roles.find(role => role.name === RoleEnum.AMBASSADOR);
  const isCampusAmbassador = roles.find(role => role.name === RoleEnum.CAMPUS_AMBASSADOR);
  const isCitizen = roles.find(role => role.name === RoleEnum.USER);
  const isVerifiedCitizen = isCitizen && verified;

  let status: UserStatus = UserStatus.USER;
  if (isAdmin) {
    status = UserStatus.ADMIN;
  } else if (isOfficial) {
    status = isCampaignProfile ? UserStatus.CAMPAIGN_PROFILE : UserStatus.ELECTED_OFFICIAL;
  } else if (isAmbassador) {
    status = UserStatus.PURPLE_AMBASSADOR;
  } else if (isCampusAmbassador) {
    status = UserStatus.CAMPUS_AMBASSADOR;
  } else if (isVerifiedCitizen) {
    status = UserStatus.VERIFIED_CITIZEN;
  } else if (isCitizen) {
    status = UserStatus.USER;
  }

  return (
    <PointerDataTableRow key={user.id} onClick={() => editUser(user.id)} $canEdit>
      <TableCell>{user.id}</TableCell>
      <TableCell>
        <NameCell>
          <StyledAvatar src={userImage} size={30} />
          {name || '-'}
        </NameCell>
      </TableCell>
      <TableCell>{username ? `@${username}` : '-'}</TableCell>
      <TableCell>{email || '-'}</TableCell>
      <TableCell>{stateAndCity || '-'}</TableCell>
      <TableCell>
        <StatusChip style={userStatusStyles[status]}>{status}</StatusChip>
      </TableCell>
      <TableCell>
        <IconButton
          icon={isFlagged ? flagFilledIcon : flagIcon}
          disabled={isLoadingFlag}
          onClick={e => {
            e.stopPropagation();
            onFlag();
          }}
        />
      </TableCell>
    </PointerDataTableRow>
  );
};

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size="large" />
    </SpinnerContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;

  height: calc(100vh - 68px); /* Subtract the TopAppBar's height */
  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    height: 100%;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 128px;
  padding: 0px 32px;
  background: linear-gradient(42.96deg, #6117ff 3.11%, #834cff 41.38%, #8601d8 99.8%);

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.md}px) {
    padding: 24px;
  }
`;
const StyledBreadCrumbs = styled(BreadCrumbs)`
  flex: 1;
  color: ${theme.white};
  margin: 12px;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    margin: 12px 0px;
  }
`;
const StyledSearchField = styled(SearchField)`
  margin: 12px;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    margin: 12px 0px;
  }
`;
const AddUserButton = styled(RoundButton)`
  &&& {
    min-width: 140px;
    margin: 12px;
    background-color: ${theme.white};
    color: ${theme.primary};

    @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
      margin: 12px 0px;
      align-self: flex-end;
    }
  }
`;

const StatusFilter = styled.div`
  overflow: auto;
  height: 54px;
`;
const StatusFilterButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  background: ${theme.white};
  padding: 0px 36px;
  align-items: center;
  min-width: 100%;
  width: max-content;
  height: 100%;
`;
const StatusFilterButton = styled(Button)<ButtonProps & { onClick: () => void; selected?: boolean }>`
  &&& {
    height: 100%;
    padding: 0px 24px;
    text-transform: capitalize;
    letter-spacing: normal;
    font-weight: 500;
    font-size: 14px;

    border-radius: 0px;
    .mdc-button__ripple {
      border-radius: 0px;
    }

    border-bottom: ${({ selected }) => (selected ? `4px solid ${theme.pink}` : 'none')};
    padding-bottom: ${({ selected }) => (selected ? 0 : 4)}px;
  }
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 32px;
  padding-bottom: 0;
  height: 0;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.md}px) {
    padding: 24px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${theme.white};
  overflow: hidden;
  box-shadow: 0px 2px 12px #bfbff7;
`;
const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
`;
const TableHeaderTitle = styled.p`
  color: ${theme.primary};
  display: inline-block;
  flex: 1;
`;
const ListIcon = styled.img`
  height: 24px;
  width: 24px;
  margin: 12px;
  user-select: none;
`;

const Table = styled(FullWidthDataTable)`
  &&& {
    border: 0;
  }
`;
const TableHeadCell = styled(InvertedDataTableHeadCell)`
  &&& {
    color: ${theme.primary};
  }
`;
const TableCell = styled(DataTableCell)`
  color: ${theme.darkGrey};
`;
const PointerDataTableRow = styled(DataTableRow)<PropsWithChildren<DataTableRowProps> & { onClick: () => void; $canEdit?: boolean }>`
  cursor: ${({ $canEdit }) => ($canEdit ? 'pointer' : 'auto')};
  height: 62px;
`;
const StyledAvatar = styled(UserAvatar)<UserAvatarProps>`
  margin-right: 12px;
`;
const StatusChip = styled.div`
  border-radius: 100px;
  padding: 6px 13px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  display: inline-block;
`;
const NameCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;
const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;
const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const FilterDrawer = styled(Drawer)<PropsWithChildren<DrawerProps & { dir?: string }>>`
  &&& {
    width: 375px;
  }
`;
const FilterDrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${theme.primary};
  padding: 10px;
`;
const FilterDrawerTitle = styled.p`
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: ${theme.primary};
  margin: 12px;
`;
const FilterDrawerContent = styled.div`
  padding: 0px 22px;
`;
const FilterDrawerLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.primary};
`;
const FilterSelect = styled.div`
  margin-bottom: 20px;
`;
const FilterButtonGroup = styled.div`
  padding-top: 12px;
`;
const FilterButton = styled(RoundButton)`
  text-transform: capitalize;
  letter-spacing: normal;
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
`;
const FilterSubmitButton = styled(FilterButton)<ButtonProps & { onClick: () => void }>`
  &&& {
    background-color: ${theme.primary};
    color: ${theme.white};
  }
`;
const FilterClearButton = styled(FilterButton)<ButtonProps & { onClick: () => void }>``;

export default UserList;
