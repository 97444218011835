import { useContext } from 'react';

import { Defaults } from '../../constants';

import { MediaContext } from './media.context';

type MediaBreakpoint = { [key in keyof typeof Defaults.MEDIA_BREAKPOINTS]: boolean } & {
  width: number;
  height: number;
  // Index signature
  [key: string]: any;
};

export function useMedia() {
  const dimension = useContext(MediaContext);

  return Object.entries(Defaults.MEDIA_BREAKPOINTS).reduce((accumulator, [size, value]) => {
    accumulator[size] = dimension.width <= value;
    accumulator.width = dimension.width;
    accumulator.height = dimension.height;
    return accumulator;
  }, {} as MediaBreakpoint);
}
