import React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import ProtectedRoute from '../../../components/ProtectedRoute';
import Vcoin from './Vcoin';

import { Defaults } from '../../../constants';
import { ModuleDefinition } from '../../../types/types';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`;

const StyledScrollContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
`;

function VcoinModule() {
  //! The order of routes is important! /all -> /:id -> /new
  const modules: ModuleDefinition[] = [
    {
      weight: 2,
      label: 'All',
      path: '/cms/vcoin/',
      component: Vcoin
    }
  ];

  return (
    <Container>
      <StyledScrollContainer>
        <Switch>
          {modules.map(({ path, component, guards }: ModuleDefinition) =>
            guards ? (
              <ProtectedRoute exact path={path} component={component} key={'module_' + path} guards={guards} redirectTo="/cms/vcoin/" />
            ) : (
              <Route exact path={path} component={component} key={'module_' + path} />
            )
          )}

          {/* If the route is not found, go to this default route */}
          <Route>
            <Redirect to="/cms/vcoin/" />
          </Route>
        </Switch>
      </StyledScrollContainer>
    </Container>
  );
}

export default VcoinModule;
