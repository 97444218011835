import * as jwt from 'jsonwebtoken';
import { useCallback, useEffect } from 'react';

import { Defaults, ErrorMessages } from '../../constants';
import { AccessToken } from '../../types/AccessToken';
import { Profile } from '../../types/Profile';
import { User } from '../../types/User';
import useUser from '../user/useUser';
import { HttpMethod, HttpStatusCode, removeAccessToken, setAccessToken } from './api';
import { useApiGet, useApiMutation } from './api.hooks';

export function useGetCurrentUser() {
  const { data, response, isLoading, error, get } = useApiGet<User | null | undefined>(undefined, 'users/me/info');
  let errorMessage;
  let returnData = data;

  useEffect(() => {
    if (response && !response.ok) {
      if (response.status === HttpStatusCode.UNAUTHORIZED || response.status === HttpStatusCode.FORBIDDEN) {
        removeAccessToken();
      }
    }
  }, [response]);

  if (response && !response.ok) {
    errorMessage = ErrorMessages.INVALID_SESSION;
    returnData = null;
  }

  return { data: returnData, isLoading, error: error || errorMessage, get };
}

export type LogInDTO = {
  email: string;
  password: string;
};

export function useLogin() {
  const [user, setUser] = useUser();
  const { data, response, isLoading, error, mutate } = useApiMutation<AccessToken, LogInDTO>(null, HttpMethod.POST, 'users/login');

  useEffect(() => {
    if (response && response.ok && data) {
      setAccessToken(data.accessToken, data.refreshToken);
      const message: any = jwt.decode(data.accessToken);
      setUser({
        id: message.sub,
        profile: message.profile,
        roles: message.roles
      });
    }
  }, [response, data, setUser]);

  const doLogin = useCallback(
    async (email: string, password: string) => {
      await mutate({ email, password });
    },
    [mutate]
  );

  return { data: data && user, isLoading, error, doLogin };
}

export function useLogout() {
  const [, setUser] = useUser();
  const { response, isLoading, error, mutate } = useApiMutation(null, HttpMethod.POST, 'users/logout');

  useEffect(() => {
    if (response && response.ok) {
      removeAccessToken();
      setUser(null);
    }
  }, [response, setUser]);

  const doLogout = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { isLoading, error, doLogout };
}

// Example of the most simple implementation
export function useGetUser(userId: string) {
  return useApiGet<User | null>(null, `users/${userId}/info`);
}

// Example of a more typical implementation
export function useGetUsersByPage() {
  const { get: originalGet, data: originalData, ...rest } = useApiGet<[User[], number]>([[], 0], 'users/');

  const get = useCallback(
    async (params: {
      page?: number;
      query?: string;
      sort?: string;
      limit?: number;
      role?: string;
      state?: string;
      city?: string;
      excludeCampaignProfile?: boolean;
      campaignProfileOnly?: boolean;
    }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZES[0]
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [data, count] = originalData;

  return { ...rest, get, data, count };
}

export type UserUpdateDTO = {
  name?: string;
  username?: string;
  email?: string;
  verified?: boolean;
  roles?: RolesDTO[];
  campaignProfile?: boolean;
};

export function useUpdateUser(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, UserUpdateDTO>(null, HttpMethod.PATCH, `users/${userId}/profile`);

  const update = useCallback(
    async (user: UserUpdateDTO) => {
      await mutate(user);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type UserCreateDTO = Pick<Profile, 'name' | 'username' | 'email'> &
  Pick<User, 'roles'> & {
    phoneNumber: string;
    password: string;
  };

export function useCreateUser() {
  const { mutate, ...rest } = useApiMutation<User, UserCreateDTO>(null, HttpMethod.POST, 'users/register');

  const create = useCallback(
    async (user: UserCreateDTO) => {
      await mutate(user);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteUser(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, null>(null, HttpMethod.DELETE, 'users/' + userId);

  const userDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, userDelete };
}

export type RolesDTO = {
  id: number;
  name: string;
};

export function useGetAllRoles() {
  return useApiGet<RolesDTO[] | null>([], 'roles');
}

export type UserFollowCountDto = {
  followers: number;
  following: number;
};

export function useFlagUser(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, null>(null, HttpMethod.POST, `users/${userId}/flag`);

  const flag = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, flag };
}

export function useUnflagUser(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, null>(null, HttpMethod.DELETE, `users/${userId}/flag`);

  const unflag = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, unflag };
}

export function useGetFlaggedUser(userId: string) {
  return useApiGet<User | null | undefined>(undefined, `users/flagged/${userId}`);
}

export function useSoftDelete(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, null>(null, HttpMethod.POST, `users/${userId}/delete`);

  const softDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, softDelete };
}

export function useHardDelete(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, null>(null, HttpMethod.DELETE, `users/${userId}`);

  const hardDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, hardDelete };
}
