export enum GenderEnum {
  MALE = 'Male',
  FEMALE = 'Female',
  NONBINARY = 'Non-Binary',
  TRANSGENDER = 'Transgender',
  DECLINE = 'Decline to state'
}
export enum AgeGroupEnum {
  YOUNGER_THAN_18 = 'Younger than 18',
  EIGHTEEN_TO_TWENTYFOUR = '18 - 24',
  TWENTYFIVE_TO_THIRTYFOUR = '25 - 34',
  THIRTYFIVE_TO_FORTYFOUR = '35 - 44',
  FORTYFIVE_TO_FIFTYFOUR = '45 - 54',
  FIFTYFIVE_TO_SIXTYFOUR = '55 - 64',
  SIXTYFIVE_AND_OLDER = '65 and older'
}
