export const Defaults = {
  ACCESS_TOKEN_COOKIE_KEY: 'accessToken',
  REFRESH_TOKEN_COOKIE_KEY: 'refreshToken',
  LIST_PAGE_SIZES: [25, 50, 75, 100],
  MEDIA_BREAKPOINTS: {
    xs: 600,
    sm: 1024,
    md: 1440,
    lg: 1920
  },
  MEDIA_DIMENSIONS: [
    {
      width: 250,
      height: 250,
      key: 'smallUrl'
    },
    {
      width: 640,
      height: 480,
      key: 'mediumUrl'
    },
    {
      width: 1024,
      height: 768,
      key: 'largeUrl'
    }
  ],
  SEARCH_DEBOUNCE_MS: 500,
  MINIMUM_PASSWORD_LENGTH: 8,
  USER_STATE: 'California'
};
