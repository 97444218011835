import { TextField as RMWCTextField } from '@rmwc/textfield';

import styled from '../../styled-components';
import { theme } from '../../constants';

const FullWidthTextField = styled(RMWCTextField)`
  /* https://stackoverflow.com/a/14205976/9183405 */
  &&&&&& {
    width: 100%;
    height: auto;

    input.mdc-text-field__input,
    input.mdc-text-field__input:-webkit-autofill,
    input.mdc-text-field__input:-webkit-autofill:hover,
    input.mdc-text-field__input:-webkit-autofill:focus,
    input.mdc-text-field__input:-webkit-autofill:active {
      padding-top: 8px;
      padding-bottom: 8px;
      color: ${theme.darkGrey};
      font-size: 14px;
      font-weight: 400;
      border-radius: 8px;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border: none;
    }
  }
`;

export function RoundTextField(props: any) {
  const { className, ...rest } = props;
  return (
    <div className={className}>
      {/* Textfield should be ALWAYS outlined for the custom CSS to work */}
      <FullWidthTextField {...rest} floatLabel={false} outlined />
    </div>
  );
}

export default RoundTextField;
