import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CircularProgress } from '@rmwc/circular-progress';
import { IconButton, IconButtonProps } from '@rmwc/icon-button';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { SimpleDialog } from '@rmwc/dialog';

import styled from '../../../styled-components';
import SelectField from '../../../components/SelectField';
import RoundTextField from '../../../components/TextField/RoundTextField';
import { UserAvatar, UserAvatarProps } from '../../../components/UserAvatar';
import RoundButton from '../../../components/Button/RoundButton';

import { Defaults, ErrorMessages, RoleEnum, SuccessMessages, theme } from '../../../constants';
import { useGetAllRoles, useGetUser, useUpdateUser, useSoftDelete, useHardDelete } from '../../../lib/api/user.hooks';
import { useError } from '../../../lib/error.hook';
import { User } from '../../../types/User';
import { UserStatus } from './UserList';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';

import { profileInfoIcon } from '../../../assets/images';

function UserEdit() {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const userId = match.params.id;

  const softDelete = useSoftDelete(userId);
  const hardDelete = useHardDelete(userId);

  const formRef = useRef<FormikProps<UserFormType>>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableDialog, setDisableDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const { get: fetchUser, ...getUser } = useGetUser(userId);
  const { get: fetchRoles, ...getRoles } = useGetAllRoles();
  const updateUser = useUpdateUser(userId);

  useError([getUser.error, getRoles.error, updateUser.error]);

  useEffect(() => {
    (async () => {
      await fetchUser();
      await fetchRoles();
      setIsLoaded(true);
    })();
  }, [fetchUser, fetchRoles]);

  useEffect(() => {
    if (updateUser.data && !updateUser.error && !updateUser.isLoading) {
      fetchUser();

      CmsSnackbarQueue.notify({
        title: SuccessMessages.USER_INFORMATION_SAVED
      });
    }
  }, [updateUser.data, updateUser.error, updateUser.isLoading, fetchUser]);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: UserFormType) => {
    setIsSubmitting(true);

    const { name, username, email, status } = values;
    const userRoles = getRoles.data || [];
    const userRole = userRoles.find(role => {
      switch (status) {
        case UserStatus.ELECTED_OFFICIAL:
        case UserStatus.CAMPAIGN_PROFILE:
          return role.name === RoleEnum.ELECTED_OFFICIAL;
        case UserStatus.PURPLE_AMBASSADOR:
          return role.name === RoleEnum.AMBASSADOR;
        case UserStatus.CAMPUS_AMBASSADOR:
          return role.name === RoleEnum.CAMPUS_AMBASSADOR;
        case UserStatus.VERIFIED_CITIZEN:
        case UserStatus.USER:
          return role.name === RoleEnum.USER;
        default:
          return false;
      }
    });

    await updateUser.update({
      name,
      username,
      email,
      verified: status !== UserStatus.USER,
      campaignProfile: status === UserStatus.CAMPAIGN_PROFILE,
      ...(userRole && { roles: [userRole] })
    });
    setIsSubmitting(false);
  };

  const user = useMemo(() => getUser.data || ({} as User), [getUser.data]);
  const { name, username, email, bio, website, verified, media } = user.profile || {};
  const { state, city } = user.vurplytic || {};
  const { followers, following } = user.stats || {};

  const { smallUrl, mediumUrl, largeUrl, originalUrl } = media || {};
  const userImage = smallUrl || mediumUrl || largeUrl || originalUrl || '';

  let stateAndCity = '';
  if (state && city) {
    stateAndCity = `${city} / ${state}`;
  } else if (state) {
    stateAndCity = state;
  } else if (city) {
    stateAndCity = city;
  }

  const roles = useMemo(() => user.roles || [], [user]);
  const isOfficial = useMemo(() => roles.find(role => role.name === RoleEnum.ELECTED_OFFICIAL), [roles]);
  const isCampaignProfile = isOfficial && user.campaignProfile?.active;
  const isAmbassador = useMemo(() => roles.find(role => role.name === RoleEnum.AMBASSADOR), [roles]);
  const isCampusAmbassador = useMemo(() => roles.find(role => role.name === RoleEnum.CAMPUS_AMBASSADOR), [roles]);
  const isCitizen = useMemo(() => roles.find(role => role.name === RoleEnum.USER), [roles]);
  const isVerifiedCitizen = isCitizen && verified;

  let status: UserStatus = UserStatus.USER;
  if (isOfficial) {
    status = isCampaignProfile ? UserStatus.CAMPAIGN_PROFILE : UserStatus.ELECTED_OFFICIAL;
  } else if (isAmbassador) {
    status = UserStatus.PURPLE_AMBASSADOR;
  } else if (isCampusAmbassador) {
    status = UserStatus.CAMPUS_AMBASSADOR;
  } else if (isVerifiedCitizen) {
    status = UserStatus.VERIFIED_CITIZEN;
  } else if (isCitizen) {
    status = UserStatus.USER;
  }

  const handleSoftDelete = async () => {
    await softDelete.softDelete();
    history.goBack();
  };

  const handleDelete = async () => {
    await hardDelete.hardDelete();
    history.goBack();
  };

  const initialValues: UserFormType = {
    name: name || '',
    username: username || '',
    email: email || '',
    status: status || ''
  };

  return (
    <Container className="main-content">
      <ContentHeader>
        <BackButton icon="arrow_back" onClick={goBack} />
        <TitleSection>
          <BreadCrumbs>{name ? `Accounts / ${name} / Profile Details` : 'Accounts / Profile Details'}</BreadCrumbs>
          <HeaderTitle>Profile Details</HeaderTitle>
        </TitleSection>
      </ContentHeader>

      <ContentBody>
        {!isLoaded ? (
          <Spinner />
        ) : (
          <>
            <LeftSection>
              <UserInfoContainer>
                <SectionHeader>
                  <SectionIcon src={profileInfoIcon} />
                  <SectionTitle>User Information</SectionTitle>
                  {isSubmitting && <CircularProgress size="small" />}
                </SectionHeader>

                <Formik
                  innerRef={formRef}
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={UserEditSchema}
                  validateOnBlur
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                      <InputGroup>
                        <FieldGroup>
                          <FieldLabel>Full Name</FieldLabel>
                          <TextField
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helpText={{ persistent: true, validationMsg: true, children: touched.name && errors.name }}
                            invalid={touched.name && errors.name}
                          />
                        </FieldGroup>

                        <FieldGroup>
                          <FieldLabel>User Name</FieldLabel>
                          <TextField
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={(values: any) => {
                              setFieldValue('username', values.target.value.replace(/\s/g, ''));
                            }}
                            onBlur={handleBlur}
                            helpText={{ persistent: true, validationMsg: true, children: touched.username && errors.username }}
                            invalid={touched.username && errors.username}
                          />
                        </FieldGroup>

                        <FieldGroup>
                          <FieldLabel>Status</FieldLabel>
                          <SelectField
                            value={{ name: values.status || '' }}
                            name="status"
                            options={userStatusOptions}
                            onChange={(val: { name: string }) => setFieldValue('status', val.name)}
                            placeholder="Select Status"
                            changeOptionLabel="name"
                            changeOptionValue="name"
                            bgColor={theme.lightestLilac}
                            color={theme.darkGrey}
                            showHelpText
                            helpText={touched.status && errors.status}
                            invalid={touched.status && errors.status}
                          />
                        </FieldGroup>

                        <FieldGroup>
                          <FieldLabel>Email Address</FieldLabel>
                          <TextField
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helpText={{ persistent: true, validationMsg: true, children: touched.email && errors.email }}
                            invalid={touched.email && errors.email}
                          />
                        </FieldGroup>
                      </InputGroup>

                      <ButtonContainer>
                        <SubmitButton type="submit" label="Submit" disabled={isSubmitting} unelevated />
                        <RightButtonsContainer>
                          <DeactivateButton
                            label={user.profile.deleted ? 'Deactivated' : 'Deactivate'}
                            disabled={isSubmitting || user.profile.deleted}
                            onClick={() => setDisableDialog(true)}
                            type="button"
                          />
                          <DeleteButton label="Delete" onClick={() => setDeleteDialog(true)} type="button" />
                        </RightButtonsContainer>
                      </ButtonContainer>
                    </Form>
                  )}
                </Formik>
              </UserInfoContainer>
            </LeftSection>

            <SimpleDialog
              title={`Deactivate User`}
              body={`Are you sure you want to deactivate this user?`}
              open={disableDialog}
              onClose={async e => {
                if (e.detail.action === 'accept') {
                  await handleSoftDelete();
                }
                setDisableDialog(false);
              }}
              acceptLabel={'Deactivate'}
            />

            <SimpleDialog
              title="Delete User"
              body="Are you sure you want to permanently delete this user?"
              open={deleteDialog}
              onClose={async e => {
                if (e.detail.action === 'accept') {
                  await handleDelete();
                }
                setDeleteDialog(false);
              }}
              acceptLabel="Delete"
            />

            <RightSection>
              <ProfileContainer>
                <ProfileHeader>
                  <StyledAvatar src={userImage} size={96} />
                  <StatsGroup>
                    <FollowStats>
                      <FollowCount>{followers || 0}</FollowCount>
                      <FollowLabel>Followers</FollowLabel>
                    </FollowStats>
                    <FollowStats>
                      <FollowCount>{following || 0}</FollowCount>
                      <FollowLabel>Following</FollowLabel>
                    </FollowStats>
                  </StatsGroup>
                </ProfileHeader>

                <ProfileText>{name}</ProfileText>

                <ProfileLabel>User Name</ProfileLabel>
                <ProfileText>{username ? `@${username}` : '-'}</ProfileText>

                <ProfileLabel>Location</ProfileLabel>
                <ProfileText>{stateAndCity || '-'}</ProfileText>

                <ProfileLabel>Bio</ProfileLabel>
                <ProfileText>{bio || '-'}</ProfileText>

                <ProfileLabel>Website</ProfileLabel>
                <ProfileText style={{ marginBottom: 12 }}>{website || '-'}</ProfileText>
              </ProfileContainer>
            </RightSection>
          </>
        )}
      </ContentBody>
    </Container>
  );
}

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size="large" />
    </SpinnerContainer>
  );
}

type UserFormType = {
  name: string;
  username: string;
  status: string;
  email: string;
};
const UserEditSchema = Yup.object().shape({
  name: Yup.string().required(ErrorMessages.REQUIRED_FULL_NAME),
  username: Yup.string().required(ErrorMessages.REQUIRED_USER_NAME),
  status: Yup.string().required(ErrorMessages.REQUIRED_STATUS),
  email: Yup.string()
    .email(ErrorMessages.INVALID_EMAIL)
    .required(ErrorMessages.REQUIRED_EMAIL)
});

const userStatusOptions = Object.values(UserStatus).map(option => ({ name: option }));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 128px;
  padding: 0px 32px;
  background: linear-gradient(42.96deg, #6117ff 3.11%, #834cff 41.38%, #8601d8 99.8%);

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.md}px) {
    padding: 24px 12px;
  }
`;
const BackButton = styled(IconButton)<IconButtonProps & { onClick: () => void }>`
  &&& {
    color: ${theme.white};
  }
`;
const TitleSection = styled.div`
  margin: 12px;
`;
const BreadCrumbs = styled.p`
  flex: 1;
  color: ${theme.white};
  font-weight: 700;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 8px;
`;
const HeaderTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: ${theme.white};
  margin: 0px;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 40px 32px;
  padding-bottom: 0;
  justify-content: center;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.md}px) {
    padding: 24px;
  }

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    flex-direction: column-reverse;
  }
`;
const LeftSection = styled.div`
  max-width: 737px;
  margin-right: 20px;
  flex: 1;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    margin-right: 0px;
    max-width: unset;
    flex: 0;
  }
`;
const SectionContainer = styled.div`
  padding: 24px;
  box-shadow: 0px 2px 12px #bfbff7;
  background-color: ${theme.white};
  border-radius: 8px;
  display: flex;
  margin-bottom: 18px;
  width: 100%;
  flex-direction: column;
  overflow: auto;
`;
const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 6px 12px;
`;
const SectionIcon = styled.img`
  height: 24px;
  width: 24px;
  margin: 0px;
  margin-right: 12px;
  user-select: none;
`;
const SectionTitle = styled.p`
  margin: 0px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: ${theme.primary};
  flex: 1;
`;

const UserInfoContainer = styled(SectionContainer)`
  padding: 24px 18px;
`;
const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;
const FieldGroup = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 0px 6px;
`;
const FieldLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: ${theme.primary};
`;
const TextField = styled(RoundTextField)`
  width: 100%;

  &&&&&& {
    input {
      background-color: ${theme.lightestLilac};
      height: 42px;
    }

    .mdc-text-field-helper-line {
      padding: 0px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 8px;
`;
const SubmitButton = styled(RoundButton)`
  &&& {
    background-color: ${theme.white};
    color: ${theme.primary};
    border: 1px solid ${theme.primary};
    text-transform: capitalize;
    letter-spacing: normal;
  }

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    margin: 12px 0px;
    align-self: flex-end;
  }
`;

const RightButtonsContainer = styled.div`
  display: flex;
`;
const DeactivateButton = styled(RoundButton)`
  &&& {
    background-color: ${theme.white};
    color: ${theme.red};
    border: 1px solid ${theme.red};
    text-transform: capitalize;
    letter-spacing: normal;
  }

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    margin: 12px 0px;
    align-self: flex-end;
  }
`;
const DeleteButton = styled(RoundButton)`
  &&& {
    color: ${theme.white};
    background-color: ${theme.red};
    border: 1px solid ${theme.red};
    text-transform: capitalize;
    letter-spacing: normal;
    margin-left: 12px;
  }

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    margin: 12px 0px;
    align-self: flex-end;
  }
`;

const ProfileContainer = styled(SectionContainer)`
  padding: 32px;
  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.sm}px) {
    padding: 24px;
  }
`;
const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
`;
const StyledAvatar = styled(UserAvatar)<UserAvatarProps>`
  margin-right: 12px;
`;
const StatsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
const FollowStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;
const FollowCount = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.darkGrey};
  margin-top: 0px;
  margin-bottom: 6px;
`;
const FollowLabel = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.darkGrey};
  margin: 0px;
`;
const ProfileLabel = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.primary};

  margin-top: 0px;
  margin-bottom: 4px;
`;
const ProfileText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.darkGrey};

  margin-top: 0px;
  margin-bottom: 21px;
`;

const RightSection = styled.div`
  max-width: 354px;
  flex: 1;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    max-width: unset;
    flex: 0;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

export default UserEdit;
