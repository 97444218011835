import { createGlobalStyle } from '../styled-components';

const baseColors = {
  atomic: '#3F4D55',
  blueWhale: '#002F49',
  black: '#000000',
  emerald: '#3AD47C',
  ghostWhite: '#F1F1F3',
  gainsboro: '#E1E1E1',
  grey: '#7f7f7f',
  matterhorn: '#565656',
  pelorous: '#2388C1',
  red: '#FF0000',
  white: '#FFFFFF',
  destructiveColor: '#FF0000',

  // vurple colors
  purple: '#7957F2',
  pink: '#9E00FF',
  violet: '#5D22FF',
  blue: '#5328FF',
  darkGrey: '#494949',
  darkViolet: '#412186',
  lightLilac: '#DEDEFF',
  lightGrey: '#ABABAB',
  lightestLilac: '#F4F4FF',
  lightestGrey: '#F1F1F1',
  lightGreyBg: '#F3F4F5',
  verifiedBlue: '#287EFF',
  purpleHeart: '#712ad2'
};

const appBaseColors = {
  primary: baseColors.purple,
  secondary: baseColors.white,
  textSecondaryOnBackground: baseColors.black
};

const componentStateColors = {
  activeNavLink: baseColors.blueWhale,
  signInButtonBackground: baseColors.emerald,
  signInFormBackground: baseColors.blueWhale,
  inputBackground: baseColors.gainsboro,
  inputLabel: baseColors.pelorous,
  inputValid: baseColors.emerald,
  inputInvalid: baseColors.red
};

const theme = {
  ...baseColors,
  ...appBaseColors,
  ...componentStateColors
};

/**
 * The floating label color bug as of today:
 * https://bitbucket.org/isbx/boilerplates-cms/pull-requests/2/feature-bplt-66-cms-set-up-theming/diff#comment-102555670
 * https://github.com/material-components/material-components-web/issues/2718
 */

export const GlobalThemeStyle = createGlobalStyle`
  ${({ theme }) => `
    html {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      background: ${theme.lightGreyBg}
    }

    html, body {
      height: 100%;
    }

    *, *:before, *:after {
      box-sizing: border-box;
    }

    :root {
      --mdc-theme-primary: ${theme.primary};
      --mdc-theme-secondary: ${theme.secondary};
      --mdc-theme-error: ${theme.destructiveColor};

      .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: var(--mdc-theme-primary);
      }

      .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
        border-color: ${theme.primary};
        background-color: ${theme.primary};
      }

      &&& {
        .mdc-drawer-scrim {
          background-color: ${theme.blue};
          opacity: 0.4;
        }
      }

      aside {
        z-index: 10;

        + .mdc-drawer-scrim {
          z-index: 9
        }
      }

      .rmwc-avatar__text {
        background: ${theme.lightGreyBg};
        height: 100%;
        width: 100%;
        max-width: none;
        max-height: none;
      }

      .rmwc-data-table__head-cell--sortable:hover {
        color: ${theme.primary};
      }
    }

    .mdc-icon-button::before, .mdc-icon-button::after {
      background-color: ${theme.primary} !important;
    }

    .picker-error .react-datepicker__input-container input {
      border: 1px solid red;
      &:focus {
        border: 2px solid red;
      }
    }
  `}
`;

export type Theme = Readonly<typeof theme>;
export { theme };
