import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { SnackbarQueue } from '@rmwc/snackbar';

import { ThemeProvider } from './styled-components';
import { theme, GlobalThemeStyle } from './constants';
import ProtectedRoute from './components/ProtectedRoute';
import MediaProvider from './lib/media/media.context';
import AdminGuard from './lib/user/admin.guard';
import UserProvider from './lib/user/user.context';
import CmsSnackbarQueue from './lib/CmsSnackbarQueue';

import CmsContainer from './features/CmsContainer';
import Login from './features/login/Login';
import LoginCheck from './features/LoginCheck';
import NotFound from './features/NotFound';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalThemeStyle />
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <MediaProvider>
              <UserProvider>
                <div className="App">
                  <Switch>
                    <Route exact path="/" component={LoginCheck} />
                    <Route exact path="/login" component={Login} />
                    {/* Protected routes */}
                    <ProtectedRoute guards={[AdminGuard]} path="/cms" component={CmsContainer} />
                    <Route component={NotFound} />
                  </Switch>
                  <SnackbarQueue messages={CmsSnackbarQueue.messages} />
                </div>
              </UserProvider>
            </MediaProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
