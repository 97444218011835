import React from 'react';
import { Redirect } from 'react-router';

import { CircularProgress } from '@rmwc/circular-progress';
import { ThemeProvider as RMWCThemeProvider } from '@rmwc/theme';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import styled from '../../styled-components';
import { ErrorMessages, RoleEnum, theme } from '../../constants';
import { LogInDTO, useLogin } from '../../lib/api/user.hooks';
import RoundTextField from '../../components/TextField/RoundTextField';
import RoundButton from '../../components/Button/RoundButton';

import { vurpleLogo } from '../../assets/images';

const EmailLogInSchema = Yup.object().shape({
  email: Yup.string()
    .email(ErrorMessages.INVALID_EMAIL)
    .required(ErrorMessages.REQUIRED_EMAIL),
  password: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD)
});

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.primary};
`;

const StyledLoginContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const StyledImageContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  height: 80px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

const ErrorContainer = styled.div`
  flex: 1;
  color: ${theme.white};
  text-align: center;
  margin: 20px 0;
`;

const StyledRoundTextField = styled(RoundTextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1;
  align-self: stretch;

  .mdc-text-field-helper-text {
    color: ${theme.white} !important;
  }

  &&&&&& {
    input.mdc-text-field__input {
      background-color: ${theme.white};
      height: 48px;
    }
  }
`;

const RoundButtonContainer = styled.div`
  margin-bottom: 16px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
`;

const StyledRoundButton = styled(RoundButton)`
  width: 180px;
  &&& {
    background-color: ${theme.violet};
  }
`;

const EmailLoginButton = styled(StyledRoundButton)`
  margin-top: 16px;
  &&& {
    background-color: ${theme.violet};
  }
`;

function LogIn() {
  const { data: user, error, doLogin } = useLogin();
  let loginError = error;

  const handleEmailSubmit = async (values: LogInDTO, actions: FormikActions<LogInDTO>) => {
    actions.setSubmitting(true);
    await doLogin(values.email, values.password);
    actions.setSubmitting(false);
  };

  if (user) {
    if ((user?.roles || []).find(r => r.name === RoleEnum.ADMIN)) {
      return <Redirect to="/cms/users/all" />;
    } else {
      loginError = new Error('You do not have sufficient permissions.');
    }
  }

  return (
    <Container>
      <StyledLoginContainer>
        <StyledImageContainer>
          <StyledImage src={vurpleLogo} />
        </StyledImageContainer>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={EmailLogInSchema}
          validateOnBlur={true}
          onSubmit={handleEmailSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <FormContainer>
              <StyledRoundTextField
                outlined
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                helpText={{ persistent: true, validationMsg: true, children: touched.email && errors.email }}
                invalid={touched.email && errors.email}
              />
              <StyledRoundTextField
                outlined
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                helpText={{ persistent: true, validationMsg: true, children: touched.password && errors.password }}
                invalid={touched.password && errors.password}
              />
              <RoundButtonContainer>
                <EmailLoginButton
                  unelevated
                  type="submit"
                  label="Log In"
                  disabled={isSubmitting}
                  icon={
                    isSubmitting ? (
                      <RMWCThemeProvider options={{ primary: theme.white }}>
                        <CircularProgress />
                      </RMWCThemeProvider>
                    ) : null
                  }
                />
              </RoundButtonContainer>
              <ErrorContainer>{loginError && loginError.message}</ErrorContainer>
            </FormContainer>
          )}
        </Formik>
      </StyledLoginContainer>
    </Container>
  );
}

export default LogIn;
