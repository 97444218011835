import React, { useState } from 'react';
import { useEffect } from 'react';

import styled from '../styled-components';
import RoundTextField from './TextField/RoundTextField';

import { theme } from '../constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 22px;
  min-width: 200px;
  max-width: 700px;
  width: 100%;
`;

const StyledRoundTextField = styled(RoundTextField)`
  &&&&&& {
    width: 100%;

    .mdc-text-field__icon--leading {
      color: ${theme.primary};
    }

    input.mdc-text-field__input {
      background-color: ${theme.white};
      height: 48px;
    }
  }
`;

export interface SearchFieldProps {
  className?: string;
  placeholder?: string;
  searchString?: string;
  onSearch: (searchString: string) => void;
}

function SearchField(props: SearchFieldProps) {
  const { className, searchString: searchStringProp, onSearch, placeholder = 'Search' } = props;
  const [searchValue, setSearchValue] = useState<string>(props.searchString || '');

  useEffect(() => {
    setSearchValue(searchStringProp || '');
  }, [searchStringProp]);

  useEffect(() => {
    onSearch(searchValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <Container className={className}>
      <StyledRoundTextField
        icon="search"
        type="text"
        placeholder={placeholder}
        value={searchValue}
        trailingIcon={
          searchStringProp &&
          searchStringProp !== '' && {
            icon: 'close',
            tabIndex: 0,
            onClick: () => onSearch('')
          }
        }
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            onSearch(searchValue);
          }
        }}
        onChange={(event: React.ChangeEvent<any>) => setSearchValue(event.target.value)}
      />
    </Container>
  );
}

export default SearchField;
