import * as EXIF from 'exif-js';

import { AWSACL } from '../../types/types';
import { Api } from '../api/api';

export enum MediaResizeAspect {
  STRETCH = 'stretch',
  FILL = 'fill',
  FIT = 'fit'
}

export enum MediaOrientation {
  HORIZONTAL_FLIP = 2,
  ROTATE_LEFT_180_DEG = 3,
  VERTICAL_FLIP = 4,
  VERTICAL_FLIP_WITH_90_DEG_ROTATE_RIGHT = 5,
  ROTATE_RIGHT_90_DEG = 6,
  HORIZONTAL_FLIP_WITH_90_DEG_ROTATE_RIGHT = 7,
  ROTATE_LEFT_90_DEG = 8
}

const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/quicktime', 'video/mp4'] as const;
export type MediaType = typeof validTypes[number];
export type MediaDimension = {
  height: number;
  width: number;
  key: string;
};
type MediaProgressFn = (progress: { loaded: number; total: number }) => any;
type MediaUploadProgress = {
  onProgress: MediaProgressFn;
};

export type MediaUploadOptions = Partial<MediaUploadProgress> & {
  resource: File | Blob;
  filename: string;
  dimensions: MediaDimension[];
  totalUploadSize?: number;
};

export type MediaResizeOptions = {
  base64Image: string;
  aspect?: MediaResizeAspect;
  dimension: MediaDimension;

  base64ToHTMLImageElementFn?: (base64Image: string) => Promise<HTMLImageElement>;
  setOrientationFn?: (options: MediaOrientationOptions) => void;
};

export type MediaOrientationOptions = {
  canvas: HTMLCanvasElement;
  context: any;
  dimension: Pick<MediaDimension, 'height' | 'width'>;
  orientation: MediaOrientation;
};

type MediaDimensionOptions = {
  resource: File | Blob;
  dimensions: MediaDimension[];
  resizerFn?: (options: MediaResizeOptions) => Promise<string>;
  blobConverterFromURLFn?: (resourceURL: string) => Blob;
};

type S3ProviderUploadOptions = MediaUploadOptions & {
  type: MediaType;
  acl: AWSACL;
};

type MediaProviderUploadResult = { [key: string]: string };
type MediaDimensionResult = Array<{ key: string; blob: Blob | File }>;

// Supported media providers
export const mediaProviders = {
  s3: async (options: S3ProviderUploadOptions): Promise<string> => {
    const { resource, type, acl, totalUploadSize = 0, onProgress = () => {} } = options;

    try {
      const [response, awsSignature] = await Api.get('media/signature', { type, acl });
      if (!response.ok) {
        throw new Error('Failed to get AWS Signature');
      }
      awsSignature.acl = acl;

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        const bucket = `https://${awsSignature.bucket}.s3.amazonaws.com`;
        const key = [awsSignature.uniqueFilePrefix, resource instanceof File ? resource.name : Date.now().toString()].join('');

        fd.append('key', key);
        fd.append('AWSAccessKeyId', awsSignature.AWSAccessKeyId);
        fd.append('acl', awsSignature.acl || 'public-read');
        fd.append('success_action_status', '201');
        fd.append('policy', awsSignature.policy);
        fd.append('signature', awsSignature.signature);
        fd.append('Content-Type', awsSignature['Content-Type']);
        fd.append('file', resource);

        xhr.open('POST', bucket, true);

        // Create strings
        xhr.onerror = () => reject('An error occurred during the transaction');

        xhr.upload.onprogress = e => onProgress({ loaded: e.loaded, total: totalUploadSize });

        xhr.onreadystatechange = () => {
          if (xhr.readyState !== xhr.DONE) return;
          const uploadedURL = [bucket, '/', key].join('');
          resolve(uploadedURL);
        };

        xhr.send(fd);
      });
    } catch (error) {
      throw error;
    }
  }
};

/**
 * Returns an invokeable function with `MediaUploadOptions` as an argument to trigger
 * the uploading to the selected media provider.
 * @param afterProviderUploadFn - Invoke this function along with the result
 * after uploading to the selected media provider.
 */
export function setupUpload(afterProviderUploadFn: (uploadResult: MediaProviderUploadResult) => Promise<any> | void) {
  return async (options: MediaUploadOptions) => {
    try {
      const [mediaFiles, totalUploadSize] = await prepareMediaFiles(options);
      const uploadResult = await reduceAsync(
        mediaFiles,
        async (accumulator, currentValue) => {
          const resourceURL = await mediaProviders.s3({
            ...options,
            totalUploadSize,
            resource: currentValue.blob,
            type: (currentValue.blob.type as MediaType) || 'image/jpeg',
            acl: 'public-read'
          });
          accumulator[currentValue.key] = resourceURL;
          accumulator.filename = options.filename;
          return accumulator;
        },
        {} as MediaProviderUploadResult
      );
      // Should we return this or let it go?
      // What result do we need? I think we need the api result
      return afterProviderUploadFn(uploadResult);
    } catch (error) {
      throw error;
    }
  };
}

/**
 * A utility method to generate dimensions based on the given set of options.
 * @param options - A set of options when generating dimensions.
 */
export async function generateDimensions(options: MediaDimensionOptions): Promise<MediaDimensionResult> {
  const { resource, dimensions, resizerFn = resize, blobConverterFromURLFn = dataURLToBlob } = options;
  const base64Image = await getBase64(resource);

  const generatedDimensionURLs = await reduceAsync(
    dimensions,
    async (accumulator, dimension) => {
      const resizedImageAsURL = await resizerFn({
        dimension,
        base64Image,
        base64ToHTMLImageElementFn: createImageElementFromBase64,
        setOrientationFn: setOrientation
      });
      accumulator.push({
        key: dimension.key,
        imageURL: resizedImageAsURL
      });
      return accumulator;
    },
    [] as Array<{ key: string; imageURL: string }>
  );

  const allFiles = generatedDimensionURLs
    .map(generatedDimensionURL => ({
      key: generatedDimensionURL.key,
      blob: blobConverterFromURLFn(generatedDimensionURL.imageURL)
    }))
    .concat([{ key: 'originalUrl', blob: resource }]); // Do not forget to return also the original file :)
  return allFiles;
}

/**
 * Reads a `File` or a `Blob` and returns a base64 image.
 * @param file - The file to use as a source of converting/reading to base64 image.
 */
export async function getBase64(file: File | Blob) {
  try {
    const base64Image = (await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => resolve(event.target.result);
      reader.onerror = error => reject(error);
    })) as string;
    return base64Image || '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

/**
 * Set the orientation with the use of context and `HTMLCanvasElement`.
 * @param options - A set of options to use when setting the orientation of the media.
 */
export function setOrientation(options: MediaOrientationOptions) {
  const {
    canvas,
    context,
    dimension: { width, height },
    orientation
  } = options;

  switch (orientation) {
    case MediaOrientation.HORIZONTAL_FLIP:
      context.translate(width, 0);
      context.scale(-1, 1);
      break;
    case MediaOrientation.ROTATE_LEFT_180_DEG:
      context.translate(width, height);
      context.rotate(Math.PI);
      break;
    case MediaOrientation.VERTICAL_FLIP:
      context.translate(0, height);
      context.scale(1, -1);
      break;
    case MediaOrientation.VERTICAL_FLIP_WITH_90_DEG_ROTATE_RIGHT:
      canvas.width = height;
      canvas.height = width;
      context.rotate(0.5 * Math.PI);
      context.scale(1, -1);
      break;
    case MediaOrientation.ROTATE_RIGHT_90_DEG:
      canvas.width = height;
      canvas.height = width;
      context.rotate(0.5 * Math.PI);
      context.translate(0, -height);
      break;
    case MediaOrientation.HORIZONTAL_FLIP_WITH_90_DEG_ROTATE_RIGHT:
      canvas.width = height;
      canvas.height = width;
      context.rotate(0.5 * Math.PI);
      context.translate(width, -height);
      context.scale(-1, 1);
      break;
    case MediaOrientation.ROTATE_LEFT_90_DEG:
      canvas.width = height;
      canvas.height = width;
      context.rotate(-0.5 * Math.PI);
      context.translate(-width, 0);
      break;
  }
}

/**
 * Resizing a media based from the given base64 image string in the `options`.
 * It uses the `EXIF` module as a way of reading tags or metadata.
 * @param options - A set of options to use when resizing a media
 */
export async function resize(options: MediaResizeOptions): Promise<string> {
  const {
    base64Image,
    aspect = 'fit',
    dimension,
    base64ToHTMLImageElementFn = createImageElementFromBase64,
    setOrientationFn = setOrientation
  } = options;

  const htmlImageElement = await base64ToHTMLImageElementFn(base64Image);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    // `EXIF.getData(img, cb)` accepts an HTMLImageElement type but the typings seems like not updated.
    EXIF.getData(htmlImageElement as any, () => {
      const orientation = EXIF.getTag(htmlImageElement, 'Orientation');
      let width = dimension.width || htmlImageElement.width;
      let height = dimension.height || htmlImageElement.height;
      let scale = 1.0;

      switch (aspect) {
        case MediaResizeAspect.STRETCH:
          canvas.width = width;
          canvas.height = height;
          break;
        case MediaResizeAspect.FILL:
          canvas.width = width;
          canvas.height = height;
          scale = Math.max(width / htmlImageElement.width, height / htmlImageElement.height);
          width = htmlImageElement.width * scale;
          height = htmlImageElement.height * scale;
          break;
        case MediaResizeAspect.FIT:
        default:
          scale = Math.min(width / htmlImageElement.width, height / htmlImageElement.height);
          if (scale > 1.0) {
            scale = 1.0; // don't enlarge the image
          }
          width = htmlImageElement.width * scale;
          height = htmlImageElement.height * scale;
          canvas.width = width;
          canvas.height = height;
          break;
      }
      context.save();
      setOrientationFn({ canvas, context, dimension: { width, height }, orientation });
      context.drawImage(htmlImageElement, 0, 0, width, height);
      context.restore();
    });
  }

  return canvas.toDataURL('image/jpeg', 0.8);
}

/**
 * Creates a `HTMLImageElement` from the given base64 resource to be resolved.
 * It also added `image.crossOrigin` to avoid CORS error.
 * - See: https://stackoverflow.com/questions/25753754/canvas-todataurl-security-error-the-operation-is-insecure
 * @param base64Image - The base64 image to use to create an `HTMLImageElement`.
 */
export async function createImageElementFromBase64(base64Resource: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = base64Resource;
  });
}

/**
 * A utility method to convert the given base64 resource to a type `Blob`.
 * @param base64Resource - The base64 resource to be converted to `Blob`.
 */
export function dataURLToBlob(base64Resource: string) {
  let byteString = '';
  if (base64Resource.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(base64Resource.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = base64Resource
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

/**
 * By using the passed file, we check if the type is an image resource by doing a regex check.
 * @param resource - The resource to check if passed file resource is an image.
 */
export function isImageResource(resource: File | Blob) {
  if (!resource || !validTypes.includes(resource.type as MediaType)) return false;
  const imageResourceRegex = /image\//gi;
  return imageResourceRegex.test(resource.type);
}

/**
 * Prepare the media files before uploading; Do the necessary checking or adjustment before uploading to the selected media provider.
 * @param options - The set of options to use for preparing media files before uploading
 */
export async function prepareMediaFiles(options: MediaUploadOptions): Promise<[{ blob: File | Blob; key: string }[], number]> {
  const { resource, dimensions } = options;
  const mediaFiles = isImageResource(resource) ? await generateDimensions({ resource, dimensions }) : [{ key: 'original', blob: resource }];
  const totalUploadSize = mediaFiles.reduce((accumulator, currentValue) => accumulator + currentValue.blob.size, 0);
  return [mediaFiles, totalUploadSize];
}

/**
 * This wraps the Array#reduce into an asynchronous flow.
 *
 * @param collection - Collection to reduce
 * @param looperFn - The function to invoke on each iteration like how Array#reduce works
 * @param initialValue - The initial value to be passed on the Array#reduce's initial value
 */
export function reduceAsync<T, C>(collection: C[], looperFn: (accumulator: T, data: C) => Promise<T>, initialValue: T) {
  return collection.reduce(async (accumulator, currentValue) => {
    const resolvedAccumulator = await accumulator;
    return await looperFn(resolvedAccumulator, currentValue);
  }, Promise.resolve(initialValue));
}
