import styled from '../styled-components';

import { avatarPlaceholderIcon } from '../assets/images';
import { theme } from '../constants';

export type UserAvatarProps = {
  src?: string;
  size?: number;
};

export const UserAvatar = ({ src, size, ...rest }: UserAvatarProps) => {
  return (
    <Container {...rest}>
      <StyledAvatar src={src || avatarPlaceholderIcon} size={size} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;
const StyledAvatar = styled.img<UserAvatarProps>`
  user-select: none;
  user-drag: none;
  background-color: ${theme.primary};

  ${({ size }) =>
    size &&
    `
      height: ${size}px;
      width: ${size}px;
      border-radius: ${Math.ceil(size / 2)}px;
  `};
`;
