import React from 'react';
import Select from 'react-select';

import { Button, ButtonProps } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

import styled from '../styled-components';
import { Defaults, theme } from '../constants';

const MAX_WIDTH = 5;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type PaginationProps = {
  className?: string;
  current: number;
  count: number;
  maxSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

const StyledButton = styled(Button)<ButtonProps & { onClick: () => void }>`
  &&& {
    min-width: 30px;
    padding: 0;
    margin: 10px 2px;
  }
`;

function Pagination(props: PaginationProps) {
  const { className, current, count = 0, maxSize = 0, onPageChange, onPageSizeChange } = props;
  const total = Math.ceil(count / maxSize);
  const range = generateRange(current, total);
  const pageSizeOptions = Defaults.LIST_PAGE_SIZES.map(size => ({ label: size, value: size }));

  return (
    <Container className={className}>
      {current !== 1 && (
        <StyledButton onClick={() => onPageChange(1)}>
          <Icon icon="first_page" />
        </StyledButton>
      )}
      {range.map(r => {
        return <StyledButton key={r} outlined={r === current} label={r} ripple={false} onClick={() => onPageChange(r)} />;
      })}
      {current !== total && (
        <StyledButton onClick={() => onPageChange(total)}>
          <Icon icon="last_page" />
        </StyledButton>
      )}
      <Select
        isSearchable={false}
        defaultValue={pageSizeOptions.find(option => option.value === maxSize) || pageSizeOptions[0]}
        options={pageSizeOptions}
        menuPlacement="auto"
        onChange={(option?: { label: number; value: number } | null) => {
          if (option) {
            onPageSizeChange(option.value);
          }
        }}
        theme={themes => ({
          ...themes,
          colors: {
            ...themes.colors,
            primary: theme.primary,
            danger: theme.destructiveColor
          }
        })}
        styles={{
          control: styles => ({
            ...styles,
            width: 96,
            marginLeft: 24
          })
        }}
      />
    </Container>
  );
}

function generateRange(current: number, total: number) {
  const half = Math.floor(MAX_WIDTH / 2);
  let start = Math.min(Math.max(current - half, 1), Math.max(total - MAX_WIDTH + 1, 1));
  let end = Math.max(Math.min(total, current + half), Math.min(total, MAX_WIDTH));

  const range: number[] = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }

  return range;
}

export default Pagination;
