import { RoleEnum } from '../../constants';
import { Guard } from '../../types/types';
import useUser from './useUser';

function AdminGuard(): Guard {
  const [user] = useUser();

  return {
    canActivate: () => {
      return !!(user?.roles || []).find(r => r.name === RoleEnum.ADMIN);
    }
  };
}

export default AdminGuard;
