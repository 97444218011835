import { DataTable, DataTableProps, DataTableContent, DataTableHeadCell, DataTableHeadCellProps } from '@rmwc/data-table';

import styled from '../styled-components';

export const FullWidthDataTable = styled(DataTable)<DataTableProps>`
  &&& {
    display: flex;
  }
` as React.FC<DataTableProps>;

export const FullWidthDataTableContent = styled(DataTableContent)`
  flex: 1;
`;

const StyledDataTableHeadCell = styled(DataTableHeadCell)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
`;

export const InvertedDataTableHeadCell = (props: DataTableHeadCellProps) => {
  const { ...rest } = props;
  return (
    <th className="rmwc-data-table__cell mdc-data-table__header-cell" style={{ display: 'table-cell', padding: 0 }}>
      <StyledDataTableHeadCell {...{ tag: 'div' }} {...rest} />
    </th>
  );
};
