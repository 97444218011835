import React, { CSSProperties, useState } from 'react';
import { Icon } from '@rmwc/icon';
import { Typography, TypographyProps } from '@rmwc/typography';
import Select, { OptionProps, OptionTypeBase } from 'react-select';

import styled from '../styled-components';
import { theme } from '../constants';

import { selectedOptionIcon } from '../assets/images';

const selectStyles = (hasError: boolean, bgColor?: string, color?: string) => ({
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    minHeight: 42
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    border: 'none',
    borderRadius: 8,
    backgroundColor: bgColor || theme.lightLilac,
    paddingLeft: 8,
    paddingRight: 8
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    color: hasError ? 'red' : color || theme.darkGrey,
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'italic'
  }),
  menuPortal: (styles: CSSProperties) => ({
    ...styles,
    zIndex: 10
  }),
  input: (styles: CSSProperties) => ({
    ...styles,
    color: theme.primary,
    fontSize: 14,
    fontWeight: 400
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    color: hasError ? 'red' : color || theme.primary,
    fontSize: 14,
    fontWeight: 400
  }),
  clearIndicator: (styles: CSSProperties) => ({
    ...styles,
    color: theme.primary,
    '&:hover': {
      color: theme.pink
    }
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    color: theme.primary,
    '&:hover': {
      color: theme.pink
    }
  }),
  indicatorSeparator: (styles: CSSProperties) => ({
    ...styles,
    display: 'none'
  })
});

function SelectField(props: any) {
  const {
    className,
    name,
    onBlur,
    label,
    invalid,
    helpText,
    showHelpText,
    changeOptionLabel,
    changeOptionValue,
    bgColor,
    color,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div className={className}>
      {label && (
        <StyledLabel use="caption" color={isFocused ? theme.primary : theme.grey} invalid={invalid}>
          {label}
        </StyledLabel>
      )}
      <Select
        styles={selectStyles(invalid, bgColor, color)}
        menuPortalTarget={document.body} // fixes menu being cut off by its parent. https://github.com/JedWatson/react-select/issues/404#issuecomment-433608100
        theme={themes => ({
          ...themes,
          colors: {
            ...themes.colors,
            primary: theme.primary,
            danger: theme.destructiveColor
          }
        })}
        onFocus={() => setIsFocused(true)}
        onBlur={(e: any) => {
          e.target.name = name;
          if (onBlur) {
            onBlur(e);
          }
          setIsFocused(false);
        }}
        getOptionLabel={(option: any) => (changeOptionLabel ? option[changeOptionLabel] : option)}
        getOptionValue={(option: any) => (changeOptionValue ? option[changeOptionValue] : option)}
        components={{ DropdownIndicator, Option }}
        {...rest}
      />
      {showHelpText && (
        <StyledLabel use="caption" color={theme.grey} invalid={invalid}>
          {helpText || ''}
        </StyledLabel>
      )}
    </div>
  );
}

const DropdownIndicator = () => {
  return <Icon icon="arrow_drop_down" style={{ color: theme.primary }} />;
};

const Option = ({ label, isSelected, ...rest }: OptionProps<OptionTypeBase, false>) => {
  const [hovered, setHovered] = useState(false);
  const onClick = rest.innerProps.onClick;

  return (
    <OptionButton onClick={(e: any) => onClick && onClick(e)} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <StyledOption style={{ backgroundColor: hovered ? theme.pink : theme.white }}>
        <OptionLabel style={{ color: hovered ? theme.white : theme.primary }}>{label}</OptionLabel>
        {isSelected && <SelectedOptionIcon src={selectedOptionIcon} />}
      </StyledOption>
    </OptionButton>
  );
};

const StyledLabel = styled(Typography)<TypographyProps & { color: string; invalid?: boolean }>`
  ${({ color, invalid }) => `
    margin-left: 16px;
    display: inline-block;
    color: ${invalid ? 'red' : color};
  `}
` as React.FC<TypographyProps & { color: string; invalid?: boolean }>;

const OptionButton = styled.a``;
const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 16px;
  margin: 0px;
  height: auto;
  border-bottom: 0.5px solid #dedeff;
  cursor: pointer;
`;
const OptionLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.primary};
  margin: 0px;
  flex: 1;
`;
const SelectedOptionIcon = styled.img`
  height: 16px;
  width: 16px;
  user-select: none;
  user-drag: none;
  margin-left: 12px;
`;

export default SelectField;
