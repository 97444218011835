import React from 'react';
import { Redirect } from 'react-router';
import { RoleEnum } from '../constants';
import useUser from '../lib/user/useUser';

function LoginCheck() {
  const [user] = useUser();
  if (user) {
    // put your default redirect here
    if ((user?.roles || []).find(r => r.name === RoleEnum.ADMIN)) {
      return <Redirect to="/cms/users/all" />;
    } else {
      return <Redirect to="/login" />;
    }
  } else if (user === null) {
    return <Redirect to="/login" />;
  }
  return null;
}

export default LoginCheck;
