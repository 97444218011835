import styled from '../../../styled-components';
import BreadCrumbs from '../../../components/Breadcrumbs';

import { Defaults } from '../../../constants';

function QuestionList() {
  return (
    <Container>
      <FullWidthBar>
        <StyledBreadCrumbs crumbs={['Questions']}></StyledBreadCrumbs>
      </FullWidthBar>
    </Container>
  );
}

const Container = styled.div`
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  display: flex;
  padding: 40px 32px;
  padding-bottom: 0;
  width: 0;

  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.md}px) {
    padding: 16px;
  }
`;
const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;
const StyledBreadCrumbs = styled(BreadCrumbs)`
  margin-bottom: 24px;
`;

export default QuestionList;
