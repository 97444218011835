import React from 'react';

import { Typography } from '@rmwc/typography';

import styled from '../styled-components';

const StyledBreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
`;

type BreadCrumbsProps = {
  className?: string;
  crumbs: string[];
  textStyles?: React.CSSProperties;
};

function BreadCrumbs(props: BreadCrumbsProps) {
  const { crumbs, className, textStyles } = props;
  return (
    <StyledBreadCrumbs className={className}>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          <Typography use="headline5" tag="span" style={{ paddingLeft: 0, paddingRight: 4, ...textStyles }}>
            {crumb}
            {crumbs.length - 1 > index && <>/</>}
          </Typography>
        </React.Fragment>
      ))}
    </StyledBreadCrumbs>
  );
}

export default BreadCrumbs;
