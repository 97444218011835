import React from 'react';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { Drawer, DrawerContent, DrawerProps } from '@rmwc/drawer';
import { Icon } from '@rmwc/icon';
import { Typography, TypographyProps } from '@rmwc/typography';

import styled from '../../styled-components';
import { theme } from '../../constants';
import { RouteDefintion } from './route-sections';

import { vurpleIcon } from '../../assets/images';

type NavigationDrawerProps = RouteComponentProps &
  DrawerProps & {
    sections: RouteDefintion[];
  };

// https://www.styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledDrawer = styled(Drawer)`
  &&& {
    background: ${theme.white};
    box-shadow: 0px 8px 16px #707df7;
    border: none;
  }
`;
const DrawerHeader = styled.div`
  height: 84px;
  width: 100%;
  padding-left: 26px;
  padding-top: 29px;
`;
const DrawerHeaderImage = styled.img`
  height: 34px;
  width: 107px;
  user-select: none;
`;

const StyledMenuItem = styled(Link)<{ selected: boolean }>`
  ${({ selected }) => `
      display: flex;
      flex-direction: row;
      background: ${selected ? theme.primary : 'none'};
      color: ${selected ? theme.white : theme.primary};
      border: none;
      text-decoration: none;
      padding: 12px 24px;
  `}
`;
const StyledMenuItemIcon = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
const StyledMenuItemText = styled(Typography as React.FC<TypographyProps>)`
  &&& {
    font-size: 16px;
    flex: 1;
    user-select: none;
  }
`;

function NavigationDrawer(props: NavigationDrawerProps) {
  const { sections, location, ...others } = props;
  const { staticContext, ...rest } = others; // Removed staticContext to fix warning

  return (
    <StyledDrawer {...rest}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerHeaderImage src={vurpleIcon} />
        </DrawerHeader>
        {sections.map(section => {
          const selected = location.pathname.indexOf(section.path) === 0;

          if (section.disabled) {
            return null;
          }

          return (
            <StyledMenuItem selected={selected} key={section.path} {...{ to: section.path }}>
              <StyledMenuItemIcon>
                <Icon icon={selected ? section.selectedIcon : section.icon} />
              </StyledMenuItemIcon>
              <StyledMenuItemText use="subtitle2">{section.name}</StyledMenuItemText>
            </StyledMenuItem>
          );
        })}
      </DrawerContent>
    </StyledDrawer>
  );
}

export default withRouter(NavigationDrawer);
