import styled from 'styled-components';

import { theme } from '../constants';

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  color: ${theme.darkGrey};
`;

type EmptyComponentProps = { message?: string };

function EmptyDataComponent(props: EmptyComponentProps) {
  const { message } = props;

  return <EmptyState>{message || 'No Data Found'}</EmptyState>;
}

export default EmptyDataComponent;
