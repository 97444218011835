import React from 'react';
import { Button } from '@rmwc/button';
import styled from '../../styled-components';

const StyledButton = styled(Button)`
  &&& {
    border-radius: 8px;
    height: 48px;
    padding: 0 24px;

    .mdc-button__ripple {
      border-radius: 8px;
    }
  }
`;

export function RoundButton(props: any) {
  const { className, ...rest } = props;
  return <StyledButton className={className} {...rest}></StyledButton>;
}

export default RoundButton;
