import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarProps,
  TopAppBarRowProps,
  TopAppBarNavigationIconProps
} from '@rmwc/top-app-bar';
import { DrawerAppContent } from '@rmwc/drawer';
import { Avatar, AvatarProps } from '@rmwc/avatar';

import styled from '../styled-components';
import { useLogout } from '../lib/api/user.hooks';
import useUser from '../lib/user/useUser';
import NavigationDrawer from './cms/NavigationDrawer';
import CMSRouteSections from './cms/route-sections';
import { theme } from '../constants';
import ProtectedRoute from '../components/ProtectedRoute';
import { useMedia } from '../lib/media';

type CmsContainerProps = RouteComponentProps;

function CmsContainer(props: CmsContainerProps) {
  const { history } = props;

  const mediaBreakpoint = useMedia();
  const [open, setOpen] = useState(false);
  const { doLogout } = useLogout();
  const [user] = useUser();

  useEffect(() => {
    setOpen(!mediaBreakpoint.sm);
  }, [mediaBreakpoint.sm]);

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [history, user]);

  const editAccount = () => {
    if (user) {
      history.push(`/cms/account/${user.id}`);
    }
  };

  const { name, username, email, media } = user?.profile || {};
  const adminName = name || username || email || 'Admin';
  const { smallUrl, mediumUrl, largeUrl, originalUrl } = media || {};
  const adminImage = smallUrl || mediumUrl || largeUrl || originalUrl || '';

  return (
    <Container>
      <Main>
        <Content>
          <StyledTopAppBar fixed>
            <StyledTopAppBarRow $drawerOpen={open}>
              <TopAppBarSection alignStart>
                <StyledTopAppBarNavigationIcon icon="menu" onClick={() => setOpen(!open)} />
              </TopAppBarSection>
              {!(open && mediaBreakpoint.xs) && (
                <TopAppBarSection alignEnd>
                  <StyledAnchor onClick={doLogout}>Logout</StyledAnchor>
                  <AnchorDivider>|</AnchorDivider>
                  <StyledAnchor onClick={() => editAccount()}>{adminName}</StyledAnchor>
                  <StyledAvatar src={adminImage} name={adminName} interactive />
                </TopAppBarSection>
              )}
            </StyledTopAppBarRow>
          </StyledTopAppBar>

          {/* Main Navigation Drawer */}
          <NavigationDrawer
            open={open}
            modal={mediaBreakpoint.sm}
            dismissible
            onClose={() => setOpen(false)}
            sections={CMSRouteSections}
            {...{ tag: 'nav' }}
          />

          {/* Main Content */}
          <AppContent>
            {CMSRouteSections.map(section => {
              if (section.guards && !isEmpty(section.guards)) {
                return <ProtectedRoute guards={section.guards} key={section.path} path={section.path} component={section.component} />;
              } else {
                return <Route key={section.path} path={section.path} component={section.component} />;
              }
            })}
          </AppContent>
        </Content>
      </Main>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;
const Main = styled.main`
  display: flex;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  background-color: ${theme.lightLilac};

  nav.mdc-drawer {
    position: fixed;
  }
`;

const AppContent = styled(DrawerAppContent)`
  flex: 1;
  display: flex;

  .main-content {
    margin-top: 68px; /* The TopAppBar's height */
    min-height: calc(100vh - 68px); /* Subtract the TopAppBar's height */
  }
`;
const StyledTopAppBar = styled(TopAppBar)<PropsWithChildren<TopAppBarProps>>`
  &&& {
    z-index: 6;
    background: ${theme.secondary};
    height: 68px;
    color: ${theme.black};
    display: flex;
    align-items: center;
  }
`;
const StyledTopAppBarRow = styled(TopAppBarRow)<PropsWithChildren<TopAppBarRowProps & { $drawerOpen?: boolean }>>`
  margin: auto;
  padding-right: 0px;
  padding-left: ${({ $drawerOpen }) => ($drawerOpen ? 256 : 0)}px;
`;
const StyledTopAppBarNavigationIcon = styled(TopAppBarNavigationIcon)<TopAppBarNavigationIconProps & { onClick: () => void }>`
  &&& {
    color: ${theme.primary};
  }
`;
const StyledAnchor = styled.a`
  padding: 0 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: ${theme.primary};
`;
const AnchorDivider = styled.div`
  color: ${theme.primary};
  margin: 8px;
`;
const StyledAvatar = styled(Avatar)<AvatarProps>`
  &&& {
    height: 40px;
    width: 40px;
    margin: 12px;
  }
`;

export default withRouter(CmsContainer);
