import UserModule from './users/UserModule';
import BroadcastModule from './broadcasts/BroadcastModule';
import QuestionModule from './questions/QuestionModule';
import VcoinModule from './vcoin/VcoinModule';

import { GuardFunc } from '../../types/types';

import {
  accountsIcon,
  accountsWhiteIcon,
  broadcastsIcon,
  broadcastsWhiteIcon,
  questionsIcon,
  questionsWhiteIcon,
  vcoinIcon,
  vcoinWhiteIcon
} from '../../assets/images';

export type RouteDefintion = {
  name: string;
  path: string;
  component: () => JSX.Element;
  icon?: string | null;
  selectedIcon?: string | null;
  guards?: GuardFunc[];
  disabled?: boolean;
};

export const CMSRouteSections: RouteDefintion[] = [
  {
    icon: accountsIcon,
    selectedIcon: accountsWhiteIcon,
    name: 'Accounts',
    path: '/cms/users',
    component: UserModule
  },
  {
    icon: broadcastsIcon,
    selectedIcon: broadcastsWhiteIcon,
    name: 'Broadcasts',
    path: '/cms/broadcasts',
    component: BroadcastModule,
    disabled: true
  },
  {
    icon: questionsIcon,
    selectedIcon: questionsWhiteIcon,
    name: 'Questions',
    path: '/cms/questions',
    component: QuestionModule,
    disabled: true
  },
  {
    icon: vcoinIcon,
    selectedIcon: vcoinWhiteIcon,
    name: 'V-Coin',
    path: '/cms/vcoin',
    component: VcoinModule,
    disabled: true
  }
];

export default CMSRouteSections;
